import React from 'react';
import { getCallHistoryAjax } from '../../ajax/callsAjax';
import { CallHistoryContext } from './Context';
import { ws_address } from '../../config';
import autobahn from "autobahn";

export function CallHistoryProvider({ children }) {
  const [call_history, setCallHistory] = React.useState([]);

  React.useEffect(() => {
    async function initCallHistory() {
      const response = await getCallHistoryAjax();
  
      setCallHistory(await response.json());
    }

    initCallHistory();

    connectToWS();
  }, []);
  
  function connectToWS() {
    let connection = new autobahn.Connection({
      url: ws_address,
      realm: "mordor"
    });

    connection.onopen = function (session) {
      session.subscribe('incoming_call', (array, obj) => {
        setCallHistory((prev) => {
          const next = [...prev];
          next.unshift(obj);
          return next;
        });
      });
    }

    connection.open();
  }

  return (
    <CallHistoryContext.Provider value={{ call_history }}>
      {children}
    </CallHistoryContext.Provider>
  );
}
import ajax from './common.js';
import { prefix } from './common.js';

export async function getUserDataAjax() {
  return await ajax({
    url: prefix + '/user'
  });
}

export async function getRepsAjax() {
  return await ajax({
    url: prefix + '/reps'
  });
}
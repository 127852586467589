import '../styles/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from '@developers/single-sign-on';
import { getUserDataAjax } from '../ajax/repsAjax';
import { CallHistoryProvider } from '../modules/CallHistory/Provider';

function MyApp({ Component, pageProps }) {

  async function getFrpUser() {
    const response = await getUserDataAjax();
    return await response.json();
  }

  return (
    <AuthProvider loadSessionData={getFrpUser}>
      <CallHistoryProvider>
        <Component {...pageProps} />
      </CallHistoryProvider>
    </AuthProvider>
  )
}

export default MyApp
